import * as React from 'react';
import {
  CircularProgress,
  FormHelperText,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import { AlertCircle } from 'icons/figma';

const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export type UserEmailInputProps = Partial<OutlinedInputProps> & {
  onChange: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => boolean;
  isLoading?: boolean;
  InputProps?: object;
  helperText?: React.ReactNode;
};

const UserEmailInput = React.forwardRef<HTMLInputElement, UserEmailInputProps>(
  (
    { onChange, onBlur = () => true, isLoading = false, helperText = '' },
    ref
  ) => {
    const [error, setError] = React.useState<string>();

    const handleBlur = React.useCallback(
      event => {
        setError(
          EMAIL_REGEX.test(event?.target?.value) || !event?.target?.value
            ? undefined
            : `'${event?.target?.value}' is not a valid email address`
        );
        return onBlur(event);
      },
      [setError, onBlur]
    );

    const handleChange = React.useCallback(
      event =>
        onChange(
          EMAIL_REGEX.test(event?.target?.value)
            ? event
            : { ...event, target: { ...event.target, value: undefined } }
        ),
      [onChange]
    );

    return (
      <>
        <OutlinedInput
          placeholder="Invite Users"
          id="model-invite-users"
          aria-describedby="model-invite-users-helper"
          fullWidth
          sx={theme => ({
            minWidth: '400px',
            ...(error
              ? {
                  '& fieldset': {
                    borderColor: theme.palette.error[500],
                  },
                }
              : undefined),
          })}
          ref={ref}
          onChange={handleChange}
          onBlur={handleBlur}
          endAdornment={
            isLoading ? <CircularProgress sx={{ mr: 2 }} size={20} /> : null
          }
        />
        {error ? (
          <FormHelperText error>
            <AlertCircle height={16} width={16} />
            {error}
          </FormHelperText>
        ) : (
          <FormHelperText id="model-invite-users-helper" sx={{ mt: 0 }}>
            {helperText}
          </FormHelperText>
        )}
      </>
    );
  }
);

UserEmailInput.displayName = 'UserEmailInput';

export default UserEmailInput;
