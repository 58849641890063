import { buttonClasses, Components, darken, Theme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonOwnProps {
    // should only be applied in case of an `href`
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#download
    download?: boolean | string;
  }
}

const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    disableRipple: true,
    disableElevation: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.button2,
      textTransform: 'none',
      borderRadius: theme.shape.radii.medium,
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.grey[400],
      },
      ':focus,.Mui-focusVisible': {
        zIndex: theme.zIndex.tooltip, // Elevate element to avoid box-shadow clipping caused by nearby elements.
        boxShadow: theme.palette.action.focusBoxShadow,
      },
      '& > *': {
        // prevents child elements from registering clicks rather than the button itself
        // to help with Google Analytics event delegation
        pointerEvents: 'none',
      },
    }),
    contained: ({ ownerState, theme }) => {
      let color: string;
      let backgroundColor: string;
      switch (ownerState?.color) {
        case 'secondary':
          color = theme.palette.primary.main;
          backgroundColor = theme.palette.primary[50];
          break;
        default:
          color = theme.palette.common.white;
          backgroundColor = theme.palette[ownerState?.color || 'primary'].main;
      }
      return {
        color,
        backgroundColor,
        ':hover': {
          backgroundColor:
            backgroundColor !== 'inherit' &&
            darken(backgroundColor, theme.palette.action.hoverOpacity),
        },
        ':active': {
          backgroundColor:
            backgroundColor !== 'inherit' &&
            darken(backgroundColor, theme.palette.action.activatedOpacity),
        },
        [`&.${buttonClasses.disabled}`]: {
          backgroundColor: theme.palette.grey[100],
        },
      };
    },
    text: ({ theme }) => ({
      color: theme.palette.text.primary,
      ':hover': {
        backgroundColor: theme.palette.primary[100],
      },
      ':active': {
        backgroundColor: theme.palette.primary[200],
      },
    }),
    sizeSmall: ({ theme }) => ({
      height: theme.spacing(8),
      padding: theme.spacing(1, 2),
    }),
    sizeMedium: ({ theme }) => ({
      height: theme.spacing(10),
      padding: theme.spacing(2, 3),
    }),
    sizeLarge: ({ theme }) => ({
      ...theme.typography.button1,
      height: theme.spacing(12),
      padding: theme.spacing(3, 4),
    }),
    startIcon: {
      marginLeft: 0,
    },
    endIcon: {
      marginRight: 0,
    },
    iconSizeSmall: ({ theme }) => ({
      '& svg': {
        // if this isn't here height won't be centered
        height: theme.spacing(4),
        width: theme.spacing(4),
      },
    }),
    iconSizeMedium: ({ theme }) => ({
      '& svg': {
        // if this isn't here height won't be centered
        height: theme.spacing(4),
        width: theme.spacing(4),
      },
    }),
    iconSizeLarge: ({ theme }) => ({
      '& svg': {
        // if this isn't here height won't be centered
        height: theme.spacing(5),
        width: theme.spacing(5),
      },
    }),
  },
};

export default MuiButton;
