import { SortDirection } from '@mui/material';
import { ERROR_IDS_TYPE } from 'common/constants/errorIDs';
import MODEL_TYPE from 'common/constants/modelType';
import { BillingData } from './Billing';
import { YesOrNo } from './common';
import { Log } from './Log';
import { Status } from './ModelRuns';
import { Report, SqsReport } from './Report';

// Model Annotations can contain any arbitrary data that is associated with a model
// including the SQS report data - we want to keep this generic since BE can add
// arbitrary data to this field. We can add more specific types as needed.
export type ModelAnnotations = {
  sqs_report?: SqsReport;
  [key: string]: unknown;
};

export type Model = {
  uid: string;
  guid: string;
  model_name: string;
  model_key: string;
  runner_mode: RunnerMode;
  user_id: string;
  user_guid: string;
  billing_domain: string;
  billing_domain_guid: string;
  project_id: string;
  project_guid: string;
  status_history: StatusHistory;
  last_modified: string;
  status: Status;
  last_active_hb: string;
  duration_minutes: null;
  error_msg: null;
  error_id?: ERROR_IDS_TYPE;
  traceback: null;
  annotations: ModelAnnotations;
  container_image: string;
  model_type: MODEL_TYPE;
  config: Config;
  autouse_config: AutouseConfig | null;
  autouse_handler_id: string;
  artifacts?: ModelArtifact[];
  report?: Report;
  logs: Log[];
  provenance: null | { workflow_run_id: string; blueprint: string };
};

export enum ModelArtifactType {
  MODEL = 'model',
  REPORT = 'report',
  REPORT_JSON = 'report_json',
  TEXT_METRICS_REPORT = 'text_metrics_report',
  TEXT_METRICS_REPORT_JSON = 'text_metrics_report_json',
  DATA_PREVIEW = 'data_preview',
  MODEL_LOGS = 'model_logs',
  RUN_LOGS = 'run_logs',
  RUN_REPORT_JSON = 'run_report_json',
  DATA = 'data',
}

export type ModelArtifact = {
  uid: string;
  project_guid: string;
  job_id: string;
  created: string;
  name: ModelArtifactType;
  format: string;
  file_size: number;
  is_compressed: boolean;
  long_name: string;
  description: string;
  mime_type: string;
  /**
   * deprecated fields:
   * extension: string; // Do not use; contains ".gz" suffix.
   * filename: string; // Do not use; contains ".gz" suffix.
   */
};

/** @TODO look at typing this, we should wait until the backend is more concrete though. -md */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ModelConfig = Record<string, any>;

export enum RunnerMode {
  MANUAL = 'manual',
  CLOUD = 'cloud',
  HYBRID = 'hybrid',
}

export enum SortField {
  LAST_MODIFIED = 'last_modified',
  CREATED = 'status_history.created',
  NAME = 'model_name',
}
export type ModelBaseQueryParams = {
  guid?: string;
  skip?: number;
  limit?: number;
  status?: string;
  sort_field?: SortField;
  sort_by?: SortDirection;

  /** Comma-separated list with any/all of: report */
  expand?: string;
};

export type GetModelsParams = ModelBaseQueryParams & {
  model_name?: string;
  model_type?: MODEL_TYPE;
};

export type GetModelsResponse = {
  models: Model[];
  total: number;
};

export type GetModelsTransformed = {
  models: Record<string, Model>;
  total: number;
};

export type GetModelParams = {
  guid: string;
  uid: string;
  logs?: YesOrNo;
  expand?: string; // Comma-separated list with any/all of: logs, report, artifacts
};

export type GetModelResponse = {
  model: Model;
  billing_data: BillingData;
  logs: Log[];
  report: Report;
  artifacts: ModelArtifact[];
};

export interface AutouseConfig {
  data_source: string;
}

export interface Config {
  schema_version: string;
  name: string;
  models: ModelElement[];
  notifications?: null;
  label_predictors?: null;
}

// TODO(INT-1855) fill this out with full config type from monogretel
export interface ModelElement {
  classify?: Classify;
  synthetics?: Synthetic;
  transform?: Transform;
  transforms?: Transform;
  gpt_x?: GPT_X;
  ctgan?: CTGan;
  actgan?: ACTGan;
  navigator?: Navigator;
  navigator_ft?: NavigatorFT;
  amplify?: Amplify;
  timeseries_dgan?: Dgan;
  tabular_dp?: TabularDP;
}

export interface GPT_X {}
export interface TabularDP {}
export interface Dgan {}
export interface Amplify {}
export interface NavigatorFT {}
export interface Navigator {
  data_source: null;
  ref_data: object;
  model_id?: string;
  output_format?: string;
}
export interface Classify {
  data_source: string[]; // url to sample dataset or just a filename;
  ref_data: object;
  use_nlp: boolean;
  return_matches: boolean;
  labels: string[];
}
export interface Synthetic {
  data_source: string[];
  validators?: {
    in_set_count?: number;
    pattern_count?: number;
    datetime_formats?: string; // 'infer'
    use_numeric_iqr?: boolean;
    allow_empty_values?: boolean;
    open_close_chars?: null;
  };
  privacy_filters?: {
    outliers?: string;
    similarity?: string;
    max_iterations?: number;
  };
  generate?: {
    num_records?: number;
    max_invalid?: null; // could this be set? i dont know
  };
  fallback?: {
    allow?: boolean;
    max_fallback_training_time_seconds?: number;
    max_fallback_generation_time_seconds?: number;
  };
  data_checks?: {
    strategy?: string; // 'log';
  };
  debug?: {
    invalid_record_cutoff?: number;
  };
  evaluate?: {
    skip?: boolean;
    target?: string;
    task?: string;
  };
  ref_data?: object;
  params?: Record<string, unknown>;
  task?: null;
}

export interface Transform {
  data_source: string[];
  ref_data?: object;
  policies: {
    name?: string;
    transform_attrs?: null;
    rules: { conditions?: [] }[];
  }[];
  enable_classification_detail?: boolean;
  use_nlp?: boolean;
}

// TODO(INT-1855) fill this out with full config type from monogretel
export interface CTGan {
  generate?: { num_records: number };
}
// TODO(INT-1855) fill this out with full config type from monogretel
export interface ACTGan {
  generate?: { num_records: number };
}

export interface StatusHistory {
  created?: string;
  pending?: string;
  active?: string;
  completed?: string;
}

/**
 * Playground uses "inference models" which are different than our
 * standard models
 */
export enum ModelMode {
  NAVIGATOR = 'TABULAR',
  NATURAL = 'NATURAL',
}
export interface InferenceModel {
  model_id: string;
  model_type: ModelMode;
  model_name: string;
}
