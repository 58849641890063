import React from 'react';
import { useParams } from 'react-router';
import { USER_ACCESS_LEVEL } from 'common/constants/userAccessLevel';
import {
  useHybridFeature,
  useProjectCreationRestricted,
} from 'common/featureFlags';
import LocalStorageHelper from 'common/localStorageHelper';
import { useProjectsQuery } from 'src/api';
import { useListClustersQuery } from 'src/api/pilot/clusters';
import { Project } from 'src/api/types/Project';

export const setLastUsedProject = (projectGuid: string) => {
  LocalStorageHelper.setItem('lastUsedProject', projectGuid);
};

export const getLastUsedProject = () => {
  let projectGuid: string | null = null;

  try {
    projectGuid = localStorage.getItem('lastUsedProject');
  } catch (err) {
    // swallow err
  }
  return projectGuid;
};

export const writeableProjectsForUser = (projects: Project[]) => {
  return projects.filter(
    project => project.level && project.level >= USER_ACCESS_LEVEL.READ_WRITE
  );
};

/**
 * This hook will return the default project guid to use. If there are no projects
 * it will return an empty string. The priority is as follows:
 * 1. If a project guid is provided in the URL, use that. This signifies we are
 *    are in the context of a specific project and that should be the default project.
 * 2. If there is a last used project persisted in local storage, use that.
 * 3. If there are projects, use the most recently updated project.
 * @returns project guid
 */
export const useDefaultOrLastUsedProject = () => {
  const params = useParams<{ project?: string }>();

  const projectsQuery = useProjectsQuery(undefined);

  const writeableProjects = React.useMemo(
    () =>
      writeableProjectsForUser(
        projectsQuery.data ? Object.values(projectsQuery.data) : []
      ),
    [projectsQuery.data]
  );

  let projectGuid = '';

  if (writeableProjects.length > 0) {
    const defaultProjectGuid = params.project ?? getLastUsedProject();
    const defaultProject = writeableProjects.find(
      project => project.guid === defaultProjectGuid
    );
    if (defaultProject) {
      projectGuid = defaultProject.guid;
    } else {
      projectGuid = writeableProjects[writeableProjects.length - 1].guid;
    }
  }

  return projectGuid;
};

export const useHybridEnabled = () => {
  const { value: hybridFeatureEnabled } = useHybridFeature();
  const { data } = useListClustersQuery({});
  const clusters = React.useMemo(() => data?.clusters ?? [], [data]);
  const hasHybridClusters = clusters.length > 0;

  return hybridFeatureEnabled || hasHybridClusters;
};

export const useZeroProjectsView = () => {
  const projectsQuery = useProjectsQuery(undefined);
  const projects = projectsQuery.data;
  const hasNoProjects = projects && Object.keys(projects).length === 0;
  const {
    value: projectCreationRestricted,
    loading: projectCreationFlagLoading,
  } = useProjectCreationRestricted();

  const noProjectsView = hasNoProjects && projectCreationRestricted;
  const loading = projectCreationFlagLoading || projectsQuery.isLoading;
  return { noProjectsView, loading };
};
