import { api } from './baseApi';

export const addTagTypes = ['NavigatorPrompts'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getNavigatorPrompts: build.query<
        GetNavigatorPromptsApiResponse,
        GetNavigatorPromptsApiArg
      >({
        query: () => ({ url: `/v1/navigator-prompts` }),
        providesTags: ['NavigatorPrompts'],
      }),
      createNavigatorPrompt: build.mutation<
        CreateNavigatorPromptApiResponse,
        CreateNavigatorPromptApiArg
      >({
        query: queryArg => ({
          url: `/v1/navigator-prompts`,
          method: 'POST',
          body: queryArg.createNavigatorPromptRequest,
        }),
        invalidatesTags: ['NavigatorPrompts'],
      }),
      searchNavigatorPrompts: build.query<
        SearchNavigatorPromptsApiResponse,
        SearchNavigatorPromptsApiArg
      >({
        query: queryArg => ({
          url: `/v1/navigator-prompts/search`,
          params: {
            query: queryArg.query,
            sort: queryArg.sort,
            limit: queryArg.limit,
            skip: queryArg.skip,
          },
        }),
        providesTags: ['NavigatorPrompts'],
      }),
      getNavigatorPrompt: build.query<
        GetNavigatorPromptApiResponse,
        GetNavigatorPromptApiArg
      >({
        query: queryArg => ({ url: `/v1/navigator-prompts/${queryArg.id}` }),
        providesTags: ['NavigatorPrompts'],
      }),
      deleteNavigatorPrompt: build.mutation<
        DeleteNavigatorPromptApiResponse,
        DeleteNavigatorPromptApiArg
      >({
        query: queryArg => ({
          url: `/v1/navigator-prompts/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['NavigatorPrompts'],
      }),
      updateNavigatorPrompt: build.mutation<
        UpdateNavigatorPromptApiResponse,
        UpdateNavigatorPromptApiArg
      >({
        query: queryArg => ({
          url: `/v1/navigator-prompts/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateNavigatorPromptRequest,
        }),
        invalidatesTags: ['NavigatorPrompts'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as NavigatorPromptsAPI };
export type GetNavigatorPromptsApiResponse =
  /** status 200 OK */ GetNavigatorPromptsResponse;
export type GetNavigatorPromptsApiArg = void;
export type CreateNavigatorPromptApiResponse =
  /** status 200 OK */ NavigatorPrompt;
export type CreateNavigatorPromptApiArg = {
  createNavigatorPromptRequest: CreateNavigatorPromptRequest;
};
export type SearchNavigatorPromptsApiResponse =
  /** status 200 OK */ SearchNavigatorPromptsResponse;
export type SearchNavigatorPromptsApiArg = {
  /** The query to search for. */
  query?: string;
  /** The sort order for the search results. */
  sort?: string;
  /** The maximum number of results to return. */
  limit?: number;
  /** The number of results to skip before applying the limit. */
  skip?: number;
};
export type GetNavigatorPromptApiResponse =
  /** status 200 OK */ NavigatorPrompt;
export type GetNavigatorPromptApiArg = {
  /** The id of the navigator prompt to get. */
  id: string;
};
export type DeleteNavigatorPromptApiResponse = /** status 200 OK */ string;
export type DeleteNavigatorPromptApiArg = {
  /** The id of the navigator prompt to delete. */
  id: string;
};
export type UpdateNavigatorPromptApiResponse =
  /** status 200 OK */ NavigatorPrompt;
export type UpdateNavigatorPromptApiArg = {
  /** The id of the navigator prompt to update. */
  id: string;
  updateNavigatorPromptRequest: UpdateNavigatorPromptRequest;
};
export type NavigatorPrompt = {
  id: string;
  name?: string;
  body: string;
  type: Type;
  created_by: string;
  created_at: string;
  updated_at: string;
};
export type GetNavigatorPromptsResponse = {
  prompts: NavigatorPrompt[];
};
export type GoogleProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type Status = {
  code?: number;
  message?: string;
  details?: GoogleProtobufAny[];
};
export type CreateNavigatorPromptRequest = {
  body: string;
  name?: string;
  type: Type;
};
export type SearchNavigatorPromptsResponse = {
  prompts: NavigatorPrompt[];
  total: number;
};
export type UpdateNavigatorPromptRequest = {
  body?: string;
  name?: string;
  type?: Type;
};
export enum Type {
  PromptTypeGpt = 'PROMPT_TYPE_GPT',
  PromptTypeTabular = 'PROMPT_TYPE_TABULAR',
}
export const {
  useGetNavigatorPromptsQuery,
  useCreateNavigatorPromptMutation,
  useSearchNavigatorPromptsQuery,
  useGetNavigatorPromptQuery,
  useDeleteNavigatorPromptMutation,
  useUpdateNavigatorPromptMutation,
} = injectedRtkApi;
